body {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

div {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
